.rwc-embed-welcome {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    min-width: 100px;
    max-width: 266px;
    padding: 16px;
    font-family: SFProText-Regular, serif;
    font-size: 16px;
    line-height: 24px;
    color: #1e232b;
    background: #fff;
    border-radius: 8px 8px 0;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1)
}

.rwc-embed-welcome__text {
    display: block
}

.rwc-embed-welcome__text.align-center, .rwc-embed-welcome__text.align-center .or-web-button {
    text-align: center
}

.rwc-embed-welcome__text .text {
    max-height: 480px;
    overflow: hidden
}

.rwc-embed-welcome__text .or-web-button {
    text-align: left
}

.rwc-embed-welcome__btn {
    margin-top: 16px
}

.rwc-embed-welcome__btn button.or-web-button.or-web-button--filled {
    max-width: 100%;
    color: #fff;
    background: var(--widgetColor, #409fec);
    border-color: var(--widgetColor, #409fec);
    border-radius: 8px
}

.rwc-embed-welcome__image {
    width: 40px;
    height: 40px;
    margin: 0 auto 16px;
    overflow: hidden;
    border-radius: 50%
}

.rwc-embed-welcome__image img {
    width: 100%;
    height: 100%;
    font-family: "object-fit: cover; object-position: center;", serif;
    -o-object-fit: cover;
    object-fit: cover
}

.rwc-embed-welcome__close {
    position: absolute;
    top: -7px;
    right: -7px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    background-color: #dfdfdf;
    border: none;
    border-radius: 50%;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1)
}

.rwc-embed-welcome__close .focus-ring {
    position: absolute;
    top: -5px;
    left: -5px;
    z-index: 999;
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, .12);
    border-radius: 50%;
    opacity: 0;
    -webkit-transition: background-color .2s ease, opacity .15s ease, -webkit-transform .15s ease;
    transition: background-color .2s ease, opacity .15s ease, -webkit-transform .15s ease;
    transition: background-color .2s ease, transform .15s ease, opacity .15s ease;
    transition: background-color .2s ease, transform .15s ease, opacity .15s ease, -webkit-transform .15s ease;
    -webkit-transform: scale(0);
    transform: scale(0)
}

.rwc-embed-welcome__close:focus .focus-ring {
    opacity: .8;
    -webkit-transform: scale(1);
    transform: scale(1)
}

.rwc-embed-welcome__close span {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 1px;
    background-color: #1e232b;
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform: translateX(-50%) translateY(-50%) rotate(45deg)
}

.rwc-embed-welcome__close span:nth-of-type(2) {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    transform: translateX(-50%) translateY(-50%) rotate(-45deg)
}

@font-face {
    font-family: Material Icons;
    font-style: normal;
    font-weight: 400;
    font-display: block;
}

.material-icons {
    font-family: Material Icons;
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    font-feature-settings: "liga" 1;
    -webkit-font-smoothing: antialiased;
    font-display: block
}

.rwc-embed-thumb {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    padding: 0;
    color: #fff;
    cursor: pointer;
    background-color: var(--widgetColor);
    border: none;
    border-radius: 50%;
    outline: none;
    -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, .13);
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, .13);
    -webkit-transition: opacity .4s, visibility .4s, -webkit-transform .4s;
    transition: opacity .4s, visibility .4s, -webkit-transform .4s;
    transition: transform .4s, opacity .4s, visibility .4s;
    transition: transform .4s, opacity .4s, visibility .4s, -webkit-transform .4s
}

@-webkit-keyframes pulse {
    0% {
        opacity: 1;
        -webkit-transform: scale(.8);
        transform: scale(.8)
    }
    to {
        opacity: 0;
        -webkit-transform: scale(1.4);
        transform: scale(1.4)
    }
}

@keyframes pulse {
    0% {
        opacity: 1;
        -webkit-transform: scale(.8);
        transform: scale(.8)
    }
    to {
        opacity: 0;
        -webkit-transform: scale(1.4);
        transform: scale(1.4)
    }
}

@-webkit-keyframes ring {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
    49% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
    50% {
        -webkit-transform: translateX(2px);
        transform: translateX(2px)
    }
    52% {
        -webkit-transform: translateX(-2px);
        transform: translateX(-2px)
    }
    55% {
        -webkit-transform: translateX(2px);
        transform: translateX(2px)
    }
    57% {
        -webkit-transform: translateX(-2px);
        transform: translateX(-2px)
    }
    60% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
    to {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes ring {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
    49% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
    50% {
        -webkit-transform: translateX(2px);
        transform: translateX(2px)
    }
    52% {
        -webkit-transform: translateX(-2px);
        transform: translateX(-2px)
    }
    55% {
        -webkit-transform: translateX(2px);
        transform: translateX(2px)
    }
    57% {
        -webkit-transform: translateX(-2px);
        transform: translateX(-2px)
    }
    60% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
    to {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@-webkit-keyframes zoom {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    50% {
        -webkit-transform: scale(1.15);
        transform: scale(1.15)
    }
}

@keyframes zoom {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    50% {
        -webkit-transform: scale(1.15);
        transform: scale(1.15)
    }
}

.rwc-embed-thumb--anim-pulse:after {
    -webkit-animation: pulse 2s ease infinite;
    animation: pulse 2s ease infinite;
    -webkit-animation-delay: .5s;
    animation-delay: .5s
}

.rwc-embed-thumb--anim-pulse:after, .rwc-embed-thumb--anim-pulse:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 99%;
    height: 99%;
    content: "";
    border: 1px solid var(--widgetColor);
    border-radius: 50%
}

.rwc-embed-thumb--anim-pulse:before {
    -webkit-animation: pulse 2s ease infinite;
    animation: pulse 2s ease infinite
}

.rwc-embed-thumb--anim-ring {
    -webkit-animation: ring 2s ease infinite;
    animation: ring 2s ease infinite
}

.rwc-embed-thumb--anim-zoom {
    -webkit-animation: zoom 2s ease infinite;
    animation: zoom 2s ease infinite
}

.rwc-embed-thumb .focus-ring {
    position: absolute;
    top: -16px;
    left: -16px;
    z-index: -1;
    width: 96px;
    height: 96px;
    background-color: rgba(0, 0, 0, .12);
    border-radius: 50%;
    opacity: 0;
    -webkit-transition: background-color .2s ease, opacity .15s ease, -webkit-transform .15s ease;
    transition: background-color .2s ease, opacity .15s ease, -webkit-transform .15s ease;
    transition: background-color .2s ease, transform .15s ease, opacity .15s ease;
    transition: background-color .2s ease, transform .15s ease, opacity .15s ease, -webkit-transform .15s ease;
    -webkit-transform: scale(0);
    transform: scale(0)
}

.rwc-embed-thumb:focus .focus-ring {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
}

@media screen and (max-width: 767px) {
    .rwc-embed-thumb--hidden {
        opacity: 0
    }
}

.rwc-embed-thumb__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 64px;
    max-width: 100%;
    height: 64px;
    max-height: 100%
}

.rwc-embed-thumb svg {
    width: 100%;
    max-width: 80%;
    height: 100%;
    max-height: 80%
}

.rwc-embed-thumb img, .rwc-embed-thumb svg {
    position: relative;
    top: 50%;
    left: 50%;
    display: block;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.rwc-embed-thumb img {
    max-width: 70%;
    max-height: 70%
}

.rwc-embed-thumb span {
    position: relative;
    display: block
}

.rwc-embed-thumb span.rwc-embed-thumb__unread {
    position: absolute;
    top: -6px;
    right: -6px;
    z-index: 3;
    padding: 6px 8.5px;
    font-size: 12px;
    line-height: 12px;
    background: #f35958;
    border-radius: 40px
}

.rwc-embed-thumb__desc {
    position: relative;
    width: 40%;
    height: 40%
}

.rwc-embed-thumb__desc span {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 3px;
    background-color: #fff;
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform: translateX(-50%) translateY(-50%) rotate(45deg)
}

.rwc-embed-thumb__desc span:nth-of-type(2) {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    transform: translateX(-50%) translateY(-50%) rotate(-45deg)
}

@font-face {
    font-family: SFProText-Regular;
    font-style: normal;
    font-weight: 400;
    src: url(https://chat.staging.onereach.ai/static/fonts/sfprotext-regular.6e1975a3.woff2) format("woff2"), url(https://chat.staging.onereach.ai/static/fonts/sfprotext-regular.6e1975a3.woff) format("woff");
    font-display: block !important
}

.rwc-embed-core {
    position: relative;
    height: 100%;
    overflow: hidden;
    font-family: SFProText-Regular, serif;
    background: var(--bodyBackground);
    -webkit-transform: translateZ(1px);
    transform: translateZ(1px)
}

.rwc-embed-core--blurred {
    background: transparent;
    border-color: hsla(0, 0%, 100%, .3);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px)
}

.rwc-embed-core--disabled {
    pointer-events: none
}

.rwc-embed-core__frame, .rwc-embed-core iframe {
    width: 100%;
    height: 100%;
    border: none
}

.rwc-embed-core__error-close {
    position: absolute;
    top: 8px;
    right: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 0;
    margin-bottom: 16px;
    color: #fff;
    cursor: pointer;
    background: rgba(0, 0, 0, .3);
    border: none;
    border-radius: 50%;
    outline: none;
    -webkit-transition: background .15s ease;
    transition: background .15s ease
}

@media screen and (min-width: 768px) {
    .rwc-embed-core__error-close {
        display: none
    }
}

.rwc-embed-core__error-close span {
    height: 18px
}

.rwc-embed-core__error-close span > svg {
    fill: #fff
}

.rwc-embed-core .rwc-modal__content {
    max-height: calc(100% - 32px)
}

.header-drag-zone {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999999;
    width: 100%;
    height: 10px;
    cursor: move
}

.rwc-embed .resizer {
    position: absolute;
    z-index: 999;
    width: 20px !important;
    height: 20px !important;
    background-color: transparent;
    opacity: 0;
    -webkit-transition: opacity .4s;
    transition: opacity .4s
}

.rwc-embed .resizer:first-child {
    top: -10px;
    left: 30px;
    cursor: nwse-resize !important
}

.rwc-embed .resizer:nth-child(2) {
    top: -10px;
    right: -10px;
    left: auto;
    cursor: nesw-resize !important
}

.rwc-embed .resizer:nth-child(3) {
    top: auto;
    bottom: -10px;
    left: 30px;
    cursor: nesw-resize !important
}

.rwc-embed .resizer:nth-child(4) {
    top: auto;
    right: -10px;
    bottom: -10px;
    left: auto;
    cursor: nwse-resize !important
}

.rwc-embed.rwc-embed--sidebar .resizer {
    position: absolute;
    top: 0 !important;
    left: -4px !important;
    z-index: 999;
    width: 8px !important;
    height: 100% !important;
    cursor: col-resize !important;
    border: none;
    opacity: 0
}

.rwc--open .rwc-embed.rwc-embed--sidebar .resizer {
    opacity: 1
}

.rwc-embed.rwc-embed--sidebar .resizer:before {
    position: absolute;
    top: 0;
    left: 3px;
    width: 2px;
    height: 100%;
    content: "";
    background-color: var(--widgetColor);
    opacity: 0;
    -webkit-transition: opacity .4s;
    transition: opacity .4s
}

.has-mouse .rwc-embed.rwc-embed--sidebar .resizer:hover:before {
    opacity: 1
}

@media screen and (max-width: 767px) {
    .rwc-embed.rwc-embed--sidebar .resizer {
        display: none
    }
}

.rwc-embed-core.blurred .rwc-embed__body .rwc-preloader, .rwc-embed-core.blurred .rwc-embed__chat, .rwc-embed.blurred .rwc-embed__body .rwc-preloader, .rwc-embed.blurred .rwc-embed__chat {
    background: transparent;
    background-color: transparent;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px)
}

.rwc-embed-core.blurred .rwc-embed__chat, .rwc-embed.blurred .rwc-embed__chat {
    border-color: hsla(0, 0%, 100%, .3)
}

.rwc-embed *, .rwc-embed-core * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.rwc-embed--bottom-left, .rwc-embed-core--bottom-left {
    right: auto !important;
    left: 30px
}

.rwc-embed--bottom-left .rwc-embed__chat-wrapper, .rwc-embed-core--bottom-left .rwc-embed__chat-wrapper {
    right: auto;
    left: 0
}

.rwc-embed-core-welcome, .rwc-embed-welcome {
    position: absolute;
    right: calc(100% + 16px);
    bottom: 0
}

.rwc-embed--bottom-left .rwc-embed-core-welcome, .rwc-embed--bottom-left .rwc-embed-welcome {
    right: auto !important;
    left: calc(100% + 24px) !important;
    border-radius: 8px 8px 0 8px !important
}

.rwc-embed--bottom-left .rwc-embed-core-welcome .rwc-embed-welcome__close, .rwc-embed--bottom-left .rwc-embed-welcome .rwc-embed-welcome__close {
    right: auto;
    left: -7px
}

.rwc-embed-core__close-btn, .rwc-embed__close-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 0;
    margin-bottom: 16px;
    color: #fff;
    cursor: pointer;
    background: rgba(0, 0, 0, .3);
    border: none;
    border-radius: 50%;
    outline: none;
    -webkit-transition: background .15s ease;
    transition: background .15s ease;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 8px
}

body[modality=keyboard] .rwc-embed-core__close-btn:focus, body[modality=keyboard] .rwc-embed__close-btn:focus {
    outline: 5px auto -webkit-focus-ring-color
}

.rwc-embed-core__close-btn span, .rwc-embed__close-btn span {
    height: 18px
}

.rwc-embed-core__close-btn span > svg, .rwc-embed__close-btn span > svg {
    fill: #fff
}

.rwc-embed-core__close-btn span.global-commands__text, .rwc-embed__close-btn span.global-commands__text {
    height: auto
}

.has-mouse .rwc-embed-core__close-btn:hover, .has-mouse .rwc-embed__close-btn:hover {
    background: rgba(0, 0, 0, .7)
}

.rwc-embed-core__close-btn:last-child, .rwc-embed__close-btn:last-child {
    margin-bottom: 0
}

.rwc-embed-core__chat-wrapper, .rwc-embed__chat-wrapper {
    position: fixed !important;
    right: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
    max-width: 100vw;
    height: 100%;
    max-height: 100vh
}

@media (min-width: 768px) {
    .rwc-embed-core__chat-wrapper, .rwc-embed__chat-wrapper {
        right: 25px;
        bottom: 25px;
        width: calc(407px + .5rem);
        height: 600px;
        max-height: calc(100vh - 50px)
    }
}

.rwc-embed-core__chat-wrapper .global-commands, .rwc-embed__chat-wrapper .global-commands {
    left: 0
}

.rwc-embed-core__chat-wrapper .global-commands__button, .rwc-embed__chat-wrapper .global-commands__button {
    background: rgba(0, 0, 0, .3);
    opacity: 1
}

.has-mouse .rwc-embed-core__chat-wrapper .global-commands__button:hover, .has-mouse .rwc-embed__chat-wrapper .global-commands__button:hover {
    background: rgba(0, 0, 0, .7)
}

.rwc-embed-core__chat-wrapper .global-commands__icon, .rwc-embed-core__chat-wrapper .global-commands__loader .progress-circular, .rwc-embed__chat-wrapper .global-commands__icon, .rwc-embed__chat-wrapper .global-commands__loader .progress-circular {
    color: #fff
}

.rwc-embed-core__chat-wrapper--reveal-fade, .rwc-embed__chat-wrapper--reveal-fade {
    -webkit-transform: none;
    transform: none
}

.rwc-embed-core__chat-wrapper--reveal-right, .rwc-embed__chat-wrapper--reveal-right {
    -webkit-transform: translateX(calc(100% + 30px));
    transform: translateX(calc(100% + 30px))
}

.rwc-embed-core__chat-wrapper--reveal-bottom, .rwc-embed__chat-wrapper--reveal-bottom {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px)
}

.rwc-embed-core__chat-wrapper--reveal-top, .rwc-embed__chat-wrapper--reveal-top {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
}

.rwc-embed-core__chat-wrapper--reveal-left, .rwc-embed__chat-wrapper--reveal-left {
    -webkit-transform: translateX(calc(-100% - 30px));
    transform: translateX(calc(-100% - 30px))
}

.rwc-embed-core__chat-wrapper .rwc-embed__controls, .rwc-embed__chat-wrapper .rwc-embed__controls {
    margin-right: 8px
}

.rwc-embed-core__chat-wrapper.fullscreen, .rwc-embed__chat-wrapper.fullscreen {
    right: 0;
    bottom: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100% !important;
    height: calc(var(--vh-static, 1vh) * 100) !important;
    background: rgba(0, 0, 0, .8)
}

.rwc-embed-core__chat-wrapper.fullscreen .global-commands, .rwc-embed__chat-wrapper.fullscreen .global-commands {
    visibility: hidden
}

.rwc-embed-core__chat-wrapper.fullscreen .rwc-embed__controls, .rwc-embed__chat-wrapper.fullscreen .rwc-embed__controls {
    z-index: 999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: auto;
    height: 44px;
    margin-right: 0
}

.rwc-embed-core__chat-wrapper.fullscreen .rwc-embed__controls button, .rwc-embed__chat-wrapper.fullscreen .rwc-embed__controls button {
    margin-left: 16px;
    background: var(--messageBackground)
}

.rwc-embed-core__chat-wrapper.fullscreen .rwc-embed__controls button span svg, .rwc-embed__chat-wrapper.fullscreen .rwc-embed__controls button span svg {
    fill: var(--textColor) !important
}

@media screen and (max-width: 767px) {
    .rwc-embed-core__chat-wrapper.fullscreen .rwc-embed__controls button span svg, .rwc-embed__chat-wrapper.fullscreen .rwc-embed__controls button span svg {
        fill: #fff !important
    }
}

.rwc-embed-core__chat-wrapper.fullscreen .rwc-embed__chat, .rwc-embed__chat-wrapper.fullscreen .rwc-embed__chat {
    padding-right: 0;
    padding-left: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px
}

.rwc-embed-core__chat-wrapper.fullscreen .rwc-embed__close-btn, .rwc-embed__chat-wrapper.fullscreen .rwc-embed__close-btn {
    margin-bottom: 0;
    background: transparent;
    -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .1);
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: auto
}

.rwc-embed-core__chat-wrapper.fullscreen .rwc-embed__close-btn > span > svg, .rwc-embed__chat-wrapper.fullscreen .rwc-embed__close-btn > span > svg {
    fill: #fff
}

.rwc-embed-core__chat, .rwc-embed__chat {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    height: 100%;
    visibility: hidden;
    background-color: var(--rwc-color-background-main);
    border: 1px solid #ccc;
    border-radius: 8px;
    -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, .13);
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, .13);
    opacity: 0;
    -webkit-transition: opacity .3s, visibility .3s, -webkit-transform .3s;
    transition: opacity .3s, visibility .3s, -webkit-transform .3s;
    transition: transform .3s, opacity .3s, visibility .3s;
    transition: transform .3s, opacity .3s, visibility .3s, -webkit-transform .3s;
    border: 1px solid var(--rwc-color-input-border-default)
}

@media (max-height: 414px)and (max-width: 896px)and (orientation: landscape) {
    .rwc-embed-core__chat, .rwc-embed__chat {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: 100%;
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (max-width: 767px) {
    .rwc-embed-core__chat, .rwc-embed__chat {
        border-radius: 0
    }
}

.rwc-embed-core__chat--shown, .rwc-embed__chat--shown {
    overflow: hidden;
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0)
}

.rwc-embed-core__body, .rwc-embed__body {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    border-radius: 8px
}

@media screen and (max-width: 767px) {
    .rwc-embed-core__body, .rwc-embed__body {
        border-radius: 0
    }
}

.rwc-embed-core__body-preview, .rwc-embed__body-preview {
    background: var(--rwc-color-background-main);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 0
}

.rwc-embed-core__body.disable-scroll, .rwc-embed__body.disable-scroll {
    overflow: hidden
}

.thumbSidebarOpen-enter-active {
    -webkit-transition: .3s;
    transition: .3s;
    -webkit-transition-delay: .1s;
    transition-delay: .1s
}

.thumbSidebarOpen-leave-active {
    -webkit-transition: .1s;
    transition: .1s
}

.thumbSidebarOpen-enter {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
}

.thumbSidebarOpen-leave-to {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

.thumbSidebarClose-enter-active {
    -webkit-transition: .3s;
    transition: .3s;
    -webkit-transition-delay: .1s;
    transition-delay: .1s
}

.thumbSidebarClose-leave-active {
    -webkit-transition: .1s;
    transition: .1s
}

.thumbSidebarClose-enter {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

.thumbSidebarClose-leave-to {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
}

.rwc-embed-thumb-sidebar {
    position: fixed;
    top: 50%;
    right: 8px;
    z-index: 99999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 36px;
    height: 128px;
    padding: 0;
    color: #fff;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    -webkit-transition: right .4s, width .4s, height .4s, -webkit-transform .4s;
    transition: right .4s, width .4s, height .4s, -webkit-transform .4s;
    transition: right .4s, width .4s, height .4s, transform .4s;
    transition: right .4s, width .4s, height .4s, transform .4s, -webkit-transform .4s;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.has-mouse .rwc-embed-thumb-sidebar:not(.rwc-embed-thumb-sidebar--open):hover .rwc-embed-thumb-sidebar__glow span {
    -webkit-transform: translate(-50px, 50px) rotate(-25deg);
    transform: translate(-50px, 50px) rotate(-25deg)
}

@-webkit-keyframes pulseSidebar {
    0% {
        opacity: 0;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    50% {
        opacity: .8
    }
    to {
        opacity: 0;
        -webkit-transform: translateX(-20px) scale(.6);
        transform: translateX(-20px) scale(.6)
    }
}

@keyframes pulseSidebar {
    0% {
        opacity: 0;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    50% {
        opacity: .8
    }
    to {
        opacity: 0;
        -webkit-transform: translateX(-20px) scale(.6);
        transform: translateX(-20px) scale(.6)
    }
}

.rwc-embed-thumb-sidebar--pulse:not(.rwc-embed-thumb-sidebar--open) .rwc-embed-thumb-sidebar__back:after, .rwc-embed-thumb-sidebar--pulse:not(.rwc-embed-thumb-sidebar--open) .rwc-embed-thumb-sidebar__back:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    pointer-events: none;
    content: "";
    border: 1px solid var(--widgetColor, #409fec);
    border-radius: 4px
}

.rwc-embed-thumb-sidebar--pulse:not(.rwc-embed-thumb-sidebar--open) .rwc-embed-thumb-sidebar__back:before {
    -webkit-animation: pulseSidebar 2s ease infinite;
    animation: pulseSidebar 2s ease infinite
}

.rwc-embed-thumb-sidebar--pulse:not(.rwc-embed-thumb-sidebar--open) .rwc-embed-thumb-sidebar__back:after {
    -webkit-animation: pulseSidebar 2s ease infinite;
    animation: pulseSidebar 2s ease infinite;
    -webkit-animation-delay: .5s;
    animation-delay: .5s
}

@-webkit-keyframes ringSidebar {
    0% {
        opacity: 1;
        -webkit-transform: rotateY(-20deg);
        transform: rotateY(-20deg)
    }
    50% {
        opacity: .7;
        -webkit-transform: rotateY(0);
        transform: rotateY(0)
    }
    to {
        opacity: 1;
        -webkit-transform: rotateY(-20deg);
        transform: rotateY(-20deg)
    }
}

@keyframes ringSidebar {
    0% {
        opacity: 1;
        -webkit-transform: rotateY(-20deg);
        transform: rotateY(-20deg)
    }
    50% {
        opacity: .7;
        -webkit-transform: rotateY(0);
        transform: rotateY(0)
    }
    to {
        opacity: 1;
        -webkit-transform: rotateY(-20deg);
        transform: rotateY(-20deg)
    }
}

.rwc-embed-thumb-sidebar--ring:not(.rwc-embed-thumb-sidebar--open) .rwc-embed-thumb-sidebar__back, .rwc-embed-thumb-sidebar--ring:not(.rwc-embed-thumb-sidebar--open) .rwc-embed-thumb-sidebar__glow {
    -webkit-animation: ringSidebar 2s ease infinite;
    animation: ringSidebar 2s ease infinite
}

@-webkit-keyframes zoomSidebar {
    0% {
        -webkit-transform: rotateY(-20deg) scale(1);
        transform: rotateY(-20deg) scale(1)
    }
    50% {
        -webkit-transform: rotateY(-20deg) scale(1.05);
        transform: rotateY(-20deg) scale(1.05)
    }
}

@keyframes zoomSidebar {
    0% {
        -webkit-transform: rotateY(-20deg) scale(1);
        transform: rotateY(-20deg) scale(1)
    }
    50% {
        -webkit-transform: rotateY(-20deg) scale(1.05);
        transform: rotateY(-20deg) scale(1.05)
    }
}

.rwc-embed-thumb-sidebar--zoom:not(.rwc-embed-thumb-sidebar--open) .rwc-embed-thumb-sidebar__back, .rwc-embed-thumb-sidebar--zoom:not(.rwc-embed-thumb-sidebar--open) .rwc-embed-thumb-sidebar__glow {
    -webkit-animation: zoomSidebar 2s ease infinite;
    animation: zoomSidebar 2s ease infinite
}

.rwc-embed-thumb-sidebar--open {
    right: 0;
    z-index: 9999;
    width: 18px;
    height: 62px;
    -webkit-transform: translateY(-50%) translateX(50%);
    transform: translateY(-50%) translateX(50%)
}

@media screen and (max-width: 767px) {
    .rwc-embed-thumb-sidebar--open {
        visibility: hidden;
        opacity: 0
    }
}

.rwc-embed-thumb-sidebar--open .rwc-embed-thumb-sidebar__back {
    -webkit-transform: none;
    transform: none
}

.rwc-embed-thumb-sidebar--open .rwc-embed-thumb-sidebar__state-wrapper {
    height: 62px
}

.rwc-embed-thumb-sidebar__state-wrapper {
    width: 100%;
    height: 128px;
    -webkit-perspective: 150px;
    perspective: 150px
}

.rwc-embed-thumb-sidebar__back {
    position: absolute !important;
    top: 0;
    left: -2px;
    z-index: -3;
    width: calc(100% + 3px);
    height: 100%;
    background-color: var(--widgetColor, #409fec);
    border-radius: 4px;
    -webkit-box-shadow: 0 3px 11px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 3px 11px 0 rgba(0, 0, 0, .1);
    -webkit-transition: top .4s, height .4s, -webkit-transform .4s;
    transition: top .4s, height .4s, -webkit-transform .4s;
    transition: transform .4s, top .4s, height .4s;
    transition: transform .4s, top .4s, height .4s, -webkit-transform .4s;
    -webkit-transform: rotateY(-20deg);
    transform: rotateY(-20deg)
}

.rwc-embed-thumb-sidebar__back--cut {
    top: 32px;
    height: calc(100% - 32px)
}

.rwc-embed-thumb-sidebar__glow {
    position: absolute !important;
    top: 0;
    left: -2px;
    z-index: -2;
    display: block;
    width: calc(100% + 3px);
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    -webkit-transform: rotateY(-20deg);
    transform: rotateY(-20deg)
}

.rwc-embed-thumb-sidebar__glow span {
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 1;
    width: 70%;
    height: 100%;
    background-color: #fff;
    opacity: .2;
    -webkit-transition: -webkit-transform .4s;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    -webkit-transform: translate(50px, -50px) rotate(-25deg);
    transform: translate(50px, -50px) rotate(-25deg)
}

.rwc-embed-thumb-sidebar__drag {
    position: absolute;
    bottom: 10px;
    left: 50%;
    cursor: move;
    opacity: .32;
    -webkit-transform: translateX(-50%) rotate(90deg);
    transform: translateX(-50%) rotate(90deg)
}

.rwc-embed-thumb-sidebar__close {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.rwc-embed-thumb-sidebar__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    max-width: 100%;
    max-height: 100%
}

.rwc-embed-thumb-sidebar__image, .rwc-embed-thumb-sidebar__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    -webkit-transform: translateZ(15px);
    transform: translateZ(15px)
}

.rwc-embed-thumb-sidebar svg {
    width: 100%;
    max-width: 80%;
    height: 100%;
    max-height: 80%
}

.rwc-embed-thumb-sidebar img, .rwc-embed-thumb-sidebar svg {
    position: relative;
    top: 50%;
    left: 50%;
    display: block;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.rwc-embed-thumb-sidebar img {
    max-width: 70%;
    max-height: 70%
}

.rwc-embed-thumb-sidebar span {
    position: relative;
    display: block
}

.rwc-embed-thumb-sidebar span.rwc-embed-thumb-sidebar__unread {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    display: inline-block;
    width: 100%;
    padding: 6px 8.5px;
    font-size: 12px;
    line-height: 12px;
    border-radius: 40px;
    -webkit-perspective: 150px;
    perspective: 150px
}

.rwc-embed-thumb-sidebar span.rwc-embed-thumb-sidebar__unread span {
    position: absolute;
    top: 7px;
    left: 0;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%
}

.rwc-embed-thumb-sidebar span.rwc-embed-thumb-sidebar__unread span:nth-child(2) {
    -webkit-transform: translateZ(20px);
    transform: translateZ(20px)
}

.rwc-embed-thumb-sidebar span.rwc-embed-thumb-sidebar__unread span.rwc-embed-thumb-sidebar__unread-back {
    position: absolute;
    top: 1px;
    right: -5px;
    left: auto;
    width: calc(100% + 13px);
    height: 25px;
    background: #f35958;
    border-radius: 4px;
    border-top-left-radius: 5px;
    -webkit-transform: rotateY(-42deg) skew(0, -9deg);
    transform: rotateY(-42deg) skew(0, -9deg)
}

.controls {
    z-index: 800;
    -ms-flex-negative: 0;
    flex-shrink: 0
}

@media screen and (max-width: 767px) {
    .controls {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 44px;
        padding: 0 8px;
        background-color: rgba(0, 0, 0, .8)
    }

    .controls button {
        background-color: transparent !important
    }

    .controls button svg {
        fill: #fff !important
    }
}

.controls button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 0;
    margin-bottom: 16px;
    color: #fff;
    cursor: pointer;
    background: rgba(0, 0, 0, .3);
    border: none;
    border-radius: 50%;
    outline: none;
    -webkit-transition: background .15s ease;
    transition: background .15s ease
}

body[modality=keyboard] .controls button:focus {
    outline: 5px auto -webkit-focus-ring-color
}

.controls button span {
    height: 18px
}

.controls button span > svg {
    fill: #fff
}

.controls button span.global-commands__text {
    height: auto
}

.has-mouse .controls button:hover {
    background: rgba(0, 0, 0, .7)
}

.controls button:last-child {
    margin-bottom: 0
}

.controls.tablet {
    border-radius: 0
}

.controls.fullscreen {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
}

.controls.fullscreen button {
    margin-bottom: 0;
    margin-left: 16px;
    background: var(--messageBackground);
    -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .1)
}

.controls.fullscreen button:focus {
    background: var(--focusColor)
}

.controls.fullscreen button > span > svg {
    fill: var(--textColor)
}

.has-mouse .controls.fullscreen button:hover {
    background: var(--focusColor)
}

.controls .rwc-gc-desktop-view .or-web-icon-button, .controls .rwc-gc-desktop-view__button {
    border: none;
    opacity: 1
}

.controls .rwc-gc-desktop-view__tooltip-text {
    height: auto
}

.messages-enter-active, .messages-leave-active {
    -webkit-transition: all .6s ease;
    transition: all .6s ease;
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom
}

.messages-enter, .messages-leave-to {
    opacity: 0;
    -webkit-transition-duration: .6s;
    transition-duration: .6s;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom
}

.fade-in-from-left-move {
    -webkit-transition: -webkit-transform .6s ease;
    transition: -webkit-transform .6s ease;
    transition: transform .6s ease;
    transition: transform .6s ease, -webkit-transform .6s ease
}

.fade-in-from-left-leave-active {
    position: absolute
}

.fade-in-from-left-enter-active, .fade-in-from-left-leave-active {
    -webkit-transition: all .6s ease;
    transition: all .6s ease;
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom
}

.fade-in-from-left-enter, .fade-in-from-left-leave-to {
    opacity: 0;
    -webkit-transform: translateX(-40px);
    transform: translateX(-40px);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom
}

.fade-in-from-right-enter-active, .fade-in-from-right-leave-active {
    -webkit-transition: all .5s ease;
    transition: all .5s ease
}

.fade-in-from-right-enter, .fade-in-from-right-leave-to {
    opacity: 0;
    -webkit-transform: translateX(40px);
    transform: translateX(40px)
}

.rwc-preloader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-family: SFProText, apple-system, BinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu antarell Fira Sans, ", ", ",    ", ",     Droid Sans", Helvetica Neue, sans-serif !important;
    font-display: swap;
    background: #f6f6f6;
    border-radius: 8px;
    -webkit-transition: opacity .4s, visibility .4s;
    transition: opacity .4s, visibility .4s
}

.rwc-preloader.dark {
    background: #161616
}

.rwc-preloader.dark .rwc-preloader__top {
    color: #fff
}

.rwc-preloader.hidden {
    visibility: hidden !important;
    opacity: 0 !important
}

.rwc-preloader.shown {
    visibility: visible;
    opacity: 1
}

.rwc-preloader__hidden {
    pointer-events: none !important;
    visibility: hidden !important;
    opacity: 0 !important
}

.rwc-preloader__top {
    height: 21px;
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 700;
    color: #1e232b
}

.rwc-preloader__bottom {
    width: 286px;
    height: 21px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #91969d;
    text-align: center;
    letter-spacing: -.15px
}

.rwc-preloader__loader {
    position: relative;
    width: 432px;
    max-width: 80%;
    height: 4px;
    margin-bottom: 16px;
    overflow: hidden;
    background-color: #dfdfdf;
    border-radius: 2px
}

@-webkit-keyframes progress {
    0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
    to {
        -webkit-transform: translateX(432px);
        transform: translateX(432px)
    }
}

@keyframes progress {
    0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
    to {
        -webkit-transform: translateX(432px);
        transform: translateX(432px)
    }
}

.rwc-preloader__inner {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 112px;
    height: 100%;
    background: #178ae7;
    border-radius: 2px;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-animation: progress 1.5s ease infinite;
    animation: progress 1.5s ease infinite
}

@media (prefers-color-scheme: dark) {
    .rwc-preloader.auto {
        background: #161616
    }

    .rwc-preloader__top {
        color: #fff
    }

    .rwc-preloader.light {
        background: #f6f6f6
    }

    .rwc-preloader.light .rwc-preloader__top {
        color: #1e232b
    }

    .rwc-preloader {
        background: #161616
    }
}

@media screen and (min-width: 767px) {
    html {

    }
}

html.rwc--open {
    width: calc(100% - var(--chatWidth))
}

@media screen and (max-width: 767px) {
    html.rwc--open {
        width: 100% !important
    }
}

html.rwc--dragged {
    -webkit-transition: none;
    transition: none
}

@media screen and (min-width: 767px) {
    body {
    }
}

.rwc-embed {
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 99999;
    width: var(--chatWidth);
    font-family: SFProText-Regular, serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.rwc-embed--sidebar {
    position: static
}

@media screen and (max-width: 767px) {
    .rwc-embed--sidebar {
        position: fixed
    }
}

@media screen and (max-width: 767px) {
    .rwc-embed--sidebar.open .rwc-embed__chat-wrapper {
        -webkit-transform: translateX(0) !important;
        transform: translateX(0) !important
    }
}

.rwc-embed--sidebar .rwc-embed__body, .rwc-embed--sidebar .rwc-preloader {
    border-radius: 0 !important
}

.rwc-embed--sidebar__controls {
    display: none
}

@media screen and (max-width: 767px) {
    .rwc-embed--sidebar__controls {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

.rwc-embed--sidebar .rwc-embed__chat-wrapper {
    position: fixed !important;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh !important;
    max-height: 100vh !important;
    -webkit-transition: -webkit-transform .4s;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

@media screen and (min-width: 767px) {
    .rwc-embed--sidebar .rwc-embed__chat-wrapper {
        width: var(--chatWidth)
    }
}

@media only screen and (max-width: 1080px) {
    .rwc-embed--sidebar .rwc-embed__chat-wrapper {
        height: calc(var(--vh-static, 1vh) * 100) !important
    }
}

@media screen and (max-width: 767px) {
    .rwc-embed--sidebar .rwc-embed__chat-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100vw;
        height: calc(var(--vh-static, 1vh) * 100) !important
    }
}

.rwc-embed--sidebar .rwc-embed__chat {
    border-radius: 0
}

.rwc-embed--sidebar .rwc-embed-welcome {
    position: fixed;
    right: 40px;
    bottom: 32px;
    z-index: 9999;
    -webkit-transition: right .2s;
    transition: right .2s
}

.tablet .rwc-embed--sidebar .rwc-embed-welcome {
    bottom: 52px
}

@media only screen and (max-width: 767px) {
    .rwc-embed--sidebar .rwc-embed-welcome {
        bottom: 16px
    }
}

.rwc-embed--sidebar .rwc-embed-welcome.right-offset {
    right: 65px
}

.rwc-embed--sidebar .rwc-embed-welcome--only-image .rwc-embed-welcome__image {
    left: 50%;
    width: 58px;
    height: 58px;
    margin: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.rwc-embed--sidebar .rwc-embed-welcome--no-button .rwc-embed-welcome__image {
    margin-bottom: 0
}

.rwc-embed--sidebar .rwc-embed-welcome__btn button.rwc-button {
    width: 100%;
    border-radius: 4px
}

.rwc-embed--sidebar .rwc-embed-welcome__image {
    position: relative;
    float: left;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    overflow: visible;
    border-radius: 4px
}

.rwc-embed--sidebar .rwc-embed-welcome__image img {
    border-radius: 4px
}

.rwc-embed--sidebar .rwc-embed-welcome__image:before {
    position: absolute;
    right: -6px;
    bottom: -2px;
    width: 10px;
    height: 10px;
    content: "";
    background-color: var(--widgetColor);
    border: 2px solid #fff;
    border-radius: 50%
}

.rwc-embed--sidebar .rwc-embed-welcome__text .text {
    margin-right: 0;
    text-align: left
}

.rwc-embed--sidebar .rwc-embed-welcome__text.align-center .text {
    text-align: center
}

.rwc-embed--left {
    right: auto;
    left: 30px
}

.rwc-embed--left .rwc-embed__welcome {
    right: auto;
    left: 40px;
    border-radius: 8px 8px 8px 0
}

.rwc-embed--left .rwc-embed__chat-wrapper {
    right: auto;
    left: 8px
}

.rwc-embed__chat-wrapper {
    position: fixed;
    right: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 100vw;
    height: 100%;
    max-height: 100vh
}

@media screen and (min-width: 767px) {
    .rwc-embed__chat-wrapper {
        right: 25px;
        bottom: 25px;
        width: calc(407px + .5rem);
        height: 600px;
        max-height: calc(100vh - 50px)
    }
}

.rwc-embed__chat {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--bodyBackground);
    border: none;
    -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, .13);
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, .13)
}

@media (max-height: 414px)and (max-width: 896px)and (orientation: landscape) {
    .rwc-embed__chat {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: 100%;
        padding-right: 0;
        padding-left: 0
    }
}

.rwc-embed__body {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    border-radius: 8px
}

@media screen and (max-width: 767px) {
    .rwc-embed__body {
        border-radius: 0
    }
}

.rwc-embed__body-preview {
    background: var(--rwc-color-background-main);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 0
}

.rwc-embed__body.disable-scroll {
    overflow: hidden
}
